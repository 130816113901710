.fixFont {
  font-size: 1em;
}
@media screen and (max-width: 676px) {
  .location_details_cardbox img {
    width: calc(100% - 20px);
    margin: 10px !important;
    border-radius: 30px;
  }
  .location_details_card {
    height: 100%;
  }
  .location_details_cardbox {
    flex-direction: column-reverse;
    width: 100%;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: #00e673;
  margin-bottom: 30px;
}
